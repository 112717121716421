/****************************************************
************** General Stylesheet Rules *************
*****************************************************
* 4 Spaces Tab Indent
* large scope style blocks (e.g: organs, pages) are marked with 3 line star comments
* Smaller sub style blocks (e.g: molecules) are marked with a single line comment
* One line space between selectors inside nested selectors
* One line space between root level selectors
* Four lines space between logical style blocks
* Order style rules corresponding to the DOM order of the elements they're applied to
*
* Order for style properties:
* 1) display
* 2) position
* 3) box-model
* 4) color/font
* 5) others
*
* If not otherwise specified the properties are ordered in the following manner:
* from outside to inside of the element (margin, border, padding, width, height)
* from top to left (top, right, bottom, left)
* alphabetical
*/




/****************************************************
***************** Dev Support Styles ****************
****************************************************/
#header,
#hero,
#desktop-nav > div,
.fl-row-content,
.container,
main,
aside {

}




/****************************************************
************** Generic Element Styles ***************
****************************************************/
img {
    max-width: 100%;
    height: auto;

    // Prevent oversize bb-plugin lightbox portrait images
    &.mfp-img {
		max-height: 80vh !important;

        @media screen and (max-width: $screen-is-max) {
            margin-top: 2em;
            max-height: 65vh !important;
        }
    }
}

::selection {
    background: $color-primary;
    color:#FFF;
}

::-moz-selection {
    background: $color-primary;
    color:#FFF;
}

// Anchor element for scroll-top btn
#top {
    visibility: hidden;
    position: absolute;
    top: 0;
}

//Content Wrapper. Everything between header and footer
.content-wrap {
    margin: 0 auto 0;

    .sidebar-primary.hero-area & {
        padding-top: 1em;
    }

    .breadcrumbs {
        text-align: center;
    }
}




/****************************************************
*************** Global Content Styles ***************
****************************************************/
main {

    a {
        color:$color-primary;
        font-weight: 700;

        &:hover{
            color:lighten($color-primary, 10%)
        }
    }

    address {
        margin: 0;
    }

    blockquote {}

    figure {

        figcaption {}
    }

    hr {}

    p {}

    .read-more {
        white-space: nowrap;

        i {
            display: inline-block;
            margin-top: -.25em;
            padding-left: .25em;
            font-size: 1em;
            vertical-align: middle;
        }
    }
}

.sticky {}

a[class^="sc-"] , span[class^="sc-"] {
    display: inline-block;
    color: inherit;
}

span[class^="sc-label"], .sc-zip {
    margin-right: .25em;
}

.sc-email {
    margin-bottom: .5em;
}

.fl-rich-text ul {
    padding-left: 2rem;
}

ol, ul {
    margin: 0;
    padding: 0;

    &.styled-list {
        li {
            position: relative;
            padding: .5em 0;
            padding-left: 1.75em;
            list-style-position: inside;
            list-style: none;
            //background-image: url(../images/leaf-icon.svg);
            background-repeat: no-repeat;
            background-position: left .75em;
        }

    }
}


// Make absolute positioned modules editable. You have to set the class
.fl-builder-edit .absolute-fl-module .fl-module-content {
    top: 0 !important;
    margin-top: 0 !important;
}

// NO-js fallback for animated bb-plugin elements
.no-js {
    .fl-animation {
        opacity: 1 !important;
    }
}

// Fix width/height 1px on svg images in widgets
.widget {
    img {
        &.svg {
            width: 100%;
        }
    }
}

.pattern-background {
    .fl-row-content-wrap {
        background-image: url(../images/background_pattern.webp);
        background-repeat: repeat;
        margin: 1rem 0;
    }
}
.custom-icon-left-dir, .custom-icon-right-dir, .fa-angle-left, .fa-angle-right {
    font-size: 4rem !important;
}
.custom-icon-left-dir {
    &:before {
        content: "\f104" !important;
        margin-left: 0 !important ;
    }
}
.custom-icon-right-dir {
    &:before {
        content: "\f105" !important;
        margin-left: 0 !important;
    }
}

.cta-btn-main .custom-icon-right-dir {
    font-size: 2.6rem !important;

    &:before {
        margin-right: 0 !important;
    }
}

@media screen and (max-width: $screen-is-max) {
    .slick-prev {
        left: -30px !important;
    }
    .uabb-blog-posts .slick-prev {
        left: 0 !important;
    }
    .slick-next {
        right: 0 !important;
    }
    .uabb-blog-posts .slick-next {
        right: 15px !important;
    }
}
