.uabb-infobox {
    // Extend the css for specific layout types with more specific selectors. E.g.:
    // &.fl-callout-center {}
    // &.fl-callout-has-photo {}
    // &.fl-callout-has-icon {}

    // Generic layout selectors
    .uabb-infobox-content {

        .uabb-image {
            .uabb-photo-img {
                width: auto;
            }
        }

        .uabb-infobox-title-wrap {
            .uabb-infobox-title {
                text-transform: none;
            }
        }

        .uabb-infobox-text-wrap {
            .uabb-infobox-text {

            }
            // CTA with button style
            .uabb-infobox-button {
                .uabb-button {
                    @extend %cta-btn;
                    @extend %cta-btn-hover;
                }
            }
        }
    }
}
.teaserbox-arrangements .uabb-infobox, .teaserbox-services .uabb-infobox {
    box-shadow: 0 0 6px rgba(0,0,0,0.16), 0 0 6px rgba(0,0,0,0.23);
    h3.uabb-infobox-title {
            font-size: 2rem;
            // font-weight: bold;
            margin: 2rem auto 2rem auto;
    }
    p {
        font-size: 1.4rem;
        margin: 0;
        padding: 0;
    }
    .uabb-infobox-button, .cta-btn {
        margin-top: 3rem;
        margin-bottom: -2rem;
        font-family: 'Oswald';
        font-size: 2rem;
        font-weight: 400;
    }
    .uabb-photo-img {
        display: block;
    }
    .uabb-image-content {
        overflow: hidden;
    }
    &:hover {
        // box-shadow: 0 0 12px rgba(0,0,0,0.16), 0 0 12px rgba(0,0,0,0.23);

        .uabb-photo-img {
            -webkit-transition: all 300ms linear;
            transition: all 300ms linear;
        }

        .uabb-photo-img {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }

        .uabb-photo-img {
            // -webkit-transform: scale(1);
            // transform: scale(1);
        }
    }
}
.teaserbox-arrangements {
    .fl-module-content {
        .uabb-infobox {
            padding: 0;
            .uabb-imgicon-wrap {
                margin: 1rem;
            }
        }
}

}
.teaserbox-services {
    .fl-module-content {
        .uabb-infobox {

            background: #fff;

            .uabb-module-content {
                margin-top: 0;
                .uabb-image {
                    .uabb-image-content {
                        .uabb-photo-img {
                        }
                    }
                }
            }

            .uabb-infobox-title-wrap {
                position: relative;
                z-index: 1;

                .uabb-infobox-title {
                    margin: 0;
                    padding: 2rem 1rem;
                    border-top: 10px solid rgba(255,255,255,.5);
                    margin-top: -10px;
                    font-family: 'Oswald';
                    font-size: 1.8rem;
                    color: $color-tertiary;
                    text-transform: uppercase;
                }
            }
        }
    }


    &.green {
        .fl-module-content {
            .uabb-infobox {

                background: $color-tertiary;

                .uabb-infobox-title-wrap {

                    .uabb-infobox-title {
                        border-top: 10px solid rgba($color-tertiary,.5);
                        color: #fff;

                    }
                }
            }
        }
    }
}

.teaserbox-frontpage {
    .uabb-infobox {
        .uabb-infobox-text {
            p {
                line-height: 3rem;
            }
        }
    }
}
.more-news {
    background-color: #F4F1EC;
    border-top: 1px solid #b1a388;
    margin-top: 5rem;

    .uabb-infobox {
        text-align: center;


        div > div > div > div.uabb-infobox-title-wrap {
            width: auto !important;

            > h3 {
                color: $color-tertiary !important;
                font-size: 1.6rem !important;
                font-weight: 500;
                text-transform: uppercase !important;
                margin: 0;
            }
        }

        div > div > div > div.uabb-imgicon-wrap {
            .uabb-icon-wrap {
                .uabb-icon i {
                    &:before {
                        font-size: auto !important;
                    }
                }
            }
        }
    }
}
