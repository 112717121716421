.uabb-infobox {
    // Extend the css for specific layout types with more specific selectors. E.g.:
    // &.fl-callout-center {}
    // &.fl-callout-has-photo {}
    // &.fl-callout-has-icon {}

    // Generic layout selectors
    .uabb-infobox-content {

        .uabb-image {}

        .uabb-infobox-title-wrap {
            .uabb-infobox-title {

            }
        }

        .uabb-infobox-text-wrap {
            .uabb-infobox-text {

            }
            // CTA with button style
            .uabb-infobox-button {
                .uabb-button {
                    @extend %cta-btn;
                    @extend %cta-btn-hover;
                }
            }
        }
    }
}

.list-advantages {
  .uabb-list-icon {
      .uabb-list-icon-wrap {
          align-items: flex-start;
          flex-basis: 40%;
          @media screen and (min-width: $screen-sm-min){
              flex-basis: 18%;
          }
      }
  }
}
_:-ms-lang(x),
.uabb-list-icon-wrap {
    padding: 10px;
}
