.hero-image-wrap {
    @media screen and (min-width: $screen-lg-min) {
        height: 650px;
    }
}
.hero-image-wrap,
.empty-hero-image,
.header-post-thumbnail,
.header-default-image,
.header-no-image {
    @extend .hero-area-content;

    .hero-image {
        @include object-fit(cover);
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .hero-image-overlay {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: ' ';
    }
}

.hero-content {
    @extend .centered-box;
    max-width: $container-md;
    text-align: center;

    .content-top-left &,
    .content-top-right &,
    .content-center-left &,
    .content-center-right &,
    .content-bottom-left &,
    .content-bottom-right & {
        transform: none;

        @media screen and (min-width: $screen-sm-min) {
            max-width: 50%;
        }
    }

    .content-bottom-center &,
    .content-top-center & {
        transform: translateX(-50%) translateY(0);
    }

    .content-center-left &,
    .content-center-right & {
        transform: translateX(0) translateY(-50%);
    }


    .content-top-left & {
        top: 0;
        right: auto;
        left: 0;
    }

    .content-top-center & {
        top: 0;
        right: auto;
        left: 50%;
    }

    .content-top-right & {
        top: 0;
        right: 0;
        left: auto;
    }

    .content-center-left & {
        right: auto;
        left: 0;
    }

    .content-center-right & {
        right: 0;
        left: auto;
    }

    .content-bottom-left & {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
    }

    .content-bottom-center & {
        top: auto;
        right: auto;
        bottom: 0;
        left: 50%;
    }

    .content-bottom-right & {
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
    }

    .hero-title {
        margin-bottom: .33em;
        color: #fff;
        font-weight: 400;
        font-family: $headings-font-family;
        font-size: 1.5em;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);

        @media screen and (min-width: $screen-sm-min) {
            font-size: 2.25em;
        }

        @media screen and (min-width: $screen-md-min) {
            font-size: 3em;
        }
    }

    .hero-text {
        color: #fff;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);

        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-large;
        }
    }

    .hero-cta {
        @extend %btn;
        @extend %cta-btn;
        @extend %cta-btn-hover;
    }
}

.header-no-image {
    background: $color-primary;
    > div {
        @extend .container;
    }
    p {
        width: 100%;
        padding: 1em;
        color: #fff;
        text-align: center;

        @media screen and (min-width: $screen-sm-min) {
            font-size: 1.5em;
        }
    }
}
