.fl-callout {
    // Extend the css for specific layout types with more specific selectors. E.g.:
    // &.fl-callout-center {}
    // &.fl-callout-has-photo {}
    // &.fl-callout-has-icon {}

    // Generic layout selectors
    .fl-callout-content {

        .fl-callout-photo {}

        .fl-icon {}

        .fl-callout-title {}

        .fl-callout-text-wrap{
            // CTA with button style
            .fl-button-wrap {
                .fl-button {
                    @extend %cta-btn;
                    @extend %cta-btn-hover;
                }
            }
            // CTA with text style
            .fl-callout-cta-link {}
        }
    }
}
.contact-person {
    .fl-callout {
        // Extend the css for specific layout types with more specific selectors. E.g.:
        // &.fl-callout-center {}
        // &.fl-callout-has-photo {}
        // &.fl-callout-has-icon {}

        // Generic layout selectors
        .fl-callout-content {

            .fl-callout-photo {
                display: inline-block;
                float: left;
            }

            .fl-icon {}

            .fl-callout-title {}

            .fl-callout-text-wrap{
                display: inline-block;
                margin: 0 0 0 2rem;
                // CTA with button style
                .fl-button-wrap {
                    .fl-button {
                        @extend %cta-btn;
                        @extend %cta-btn-hover;
                    }
                }
                // CTA with text style
                .fl-callout-cta-link {}
            }
        }
    }
}
