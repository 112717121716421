body {
    color: $color-secondary;
}

.content-wrap,
#tinymce,
#footer {
    h1,
    h2,
    h3,
    h4 {

    }

    h1{
        font-weight: 400;
        font-family: 'Oswald';
        text-transform: uppercase;
        color: $color-primary;
        font-size: 3rem;
        line-height: 4rem;
        text-align: center;

        @media screen and (min-width: $screen-is-min) {
            font-size: 4.5rem;
            line-height: 6rem;
            text-align: left;
        }

        @media screen and (min-width: $screen-sm-min) {

        }

    }

    .heading-subpages {
        h1 {
            font-size: 3rem;
            margin: 0;
            line-height: 4rem;

            @media screen and (min-width: $screen-sm-min) {
                line-height: 1rem;
            }
        }
        p {
            font-size: 2.5rem !important;
            font-weight: 100 !important;
            margin: 0;
        }
    }

    .textbox-subpages-with-heading {
        p:first-child {
            color: $color-primary;
            font-weight: bold;
        }
    }

    h2 {
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
        color: #63605e;
        font-size: 2.5rem;
        text-align: center;

        @media screen and (min-width: $screen-is-min) {
            font-size: 3rem;
            text-align: left;
        }
    }

    h3 {
        color: $color-primary;
        font-family: 'Oswald';
        font-size: 3rem;
        text-transform: uppercase;

        .uabb-heading {
            // display: inline-block !important;
            // border: 10px solid #000;

            &:after {
                border-top: 1px solid #000;
            }
        }

        .uabb-infobox-title {
            font-size: 2rem;
            margin-top: 1rem;
            border: 1px solid black;
        }
    }

    h4 {
        font-size: 2rem;
        font-family: 'Alegreya Sans', sans-serif;
        font-style: italic;
        font-weight: bold;
    }
}
.uabb-infobox-text, .uabb-text-editor {
    color: $color-secondary!important;
}
.uabb-subheading {
    p {
        font-family: 'Oswald' !important;
        font-size: 2rem !important;
    }
}

.heading-border {
    div {
        text-align: center;

        h3.uabb-heading {
            position: relative;
            padding-bottom: 2rem;
            display: inline-block;

            &::after {
                content: " ";
                border-bottom: 1px solid #b1a388;
                width: 130%;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
        }
    }
}

.tagline{
    font-weight:300;
    font-size: $font-size-large;
}
.testimonials .uabb-blog-posts-description {
    font-size: 1.6rem;
    font-family: 'Alegreya Sans', sans-serif;
    line-height: 2.4rem;
}
.newsletter {

    font-family: 'Oswald';
    color: #fff;

    strong {
        text-transform: uppercase;
        font-size: 2.4rem;
    }
    p {
        font-weight: 100;
        font-size: 1.8rem;
    }
    input {
        border: 0;
        background: #c0c0c0;
        padding: 1rem;
        width: 100%;
        // height: 6rem;
    }
    .newsletter-email input[type='email'] {
        border: 0;
        border-radius: 0;
        background: #c0c0c0;
        color: #63605e !important;
        padding: 1rem !important;
        width: 100%;
        font-size: 1.8rem;
        height: auto!important;
    }
    .cta-btn {
        a {
            padding: 0;
            span {
                // padding: 1rem;
                // height: 6rem;
                display: block;
            }

        }
    }
    // .fl-node-5a6a05e032345 div { display: inline; }
}
.teaserbox-promotion {
    p {
        color: #fff;
        text-align: center;
    }
}
