/*******************************************************
**** Overflow settings for fixed navbar / offcanvas ****
*******************************************************/
@media screen and (max-width: $screen-is-max) {
	body,
	html,
	.off-canvas-wrap,
	.off-canvas-wrap .inner-wrap,
	.page-wrap {
		height: 100%;
	}

	.page-wrap {
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;

		.move-left & {
			overflow-x: auto;
		}
	}
}



/***********************************************************
*************** TOPBAR STYLING (OFFCANVAS)******************
* See /common/variables.scss for animation settings
***********************************************************/
.topbar {
	transition-property: transform;
	transition-duration: $offcanvas-duration;
	transition-delay: $offcanvas-delay;
	transition-timing-function: $offcanvas-timing;

	.move-left & {
		transform: translate3d(- $offcanvas-width, 0, 0);
	}
}



/***********************************************************
****************** OFF-CANVAS-STYLES ***********************
* See /common/variables.scss for color & animation settings
***********************************************************/
.off-canvas-wrap {
	position: relative;
	width: 100%;
	overflow: hidden;
	-webkit-backface-visibility: hidden;

	&.move-right, &.move-left {
		min-height: 100%;
		-webkit-overflow-scrolling: touch;

		.exit-off-canvas {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1002;
			background: rgba(0, 0, 0, 0.2);
			transition: background 300ms ease;
			cursor: pointer;
			-webkit-backface-visibility: hidden;
			-webkit-tap-highlight-color: transparent;
		}
	}

	&.move-right {
		// Shift Content to the right
		> .inner-wrap {
			transform: translate3d($offcanvas-width, 0, 0);
		}
	}

	&.move-left {
		// Shift Content to the left
		> .inner-wrap {
			transform: translate3d(- $offcanvas-width, 0, 0);
		}
	}

	// Content wrapper
	.inner-wrap {
		position: relative;
		z-index: 1001;
		width: 100%;
		transition-property: transform;
		transition-duration: $offcanvas-duration;
		transition-delay: $offcanvas-delay;
		transition-timing-function: $offcanvas-timing;

		&::before, &::after {
			display: table;
			content: " ";
		}

		&::after {
			clear: both;
		}
	}

	// Right offcanvas menu styles
	.right-off-canvas-menu {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		box-sizing: content-box;
		width: $offcanvas-width;
		background: $offcanvas-bg;
		overflow-x: hidden;
		overflow-y: auto;
		transition-property: transform;
		transition-duration: $offcanvas-duration;
		transition-delay: $offcanvas-delay;
		transition-timing-function: $offcanvas-timing;
		transform: translate3d(100%, 0, 0);
		-webkit-backface-visibility: hidden;
		-webkit-overflow-scrolling: touch;

		@media screen and (min-width: $screen-sm-min) {
			display: none;
		}

		.fl-builder-edit & {
			display: none;
		}

		// First Level ul
		.nav, .menu {
			@extend .nav;
			// General Menu Item Styles

			.menu-item {
				// Toggles the sub-menus based on class
				&.open {
					> .sub-menu {
						display: block;
					}

					> .toggle {
						i {
							transform: rotate(90deg);
							-ms-transform: rotate(90deg);
						}
					}
				}
			}

			> .menu-item {
				border-bottom: 1px solid $gray-lighter;

				a {
					display: inline-block;
					padding: $grid-gutter-width / 2;
					padding-right: 0;
					width: 85%;
				}

				// Toggle Indicators
				.toggle {
					display: inline-block;
					padding: $grid-gutter-width / 2 0;
					width: 15%;
					text-align: center;
					border-left: 1px solid #efefef;

					i {
						display: block;
						line-height: inherit;
						transition: all 200ms ease-in-out;
						transform: rotate(0deg);
						-ms-transform: rotate(0deg);

						&::before {
							vertical-align: middle;
						}
					}
				}

				// Close sub-menus by default
				.sub-menu {
					display: none;
					background: rgba($gray-base, 0.1);

					.menu-item {

						&.current-menu-ancestor,
						&.current-menu-parent,
						&.current-menu-item {
							> a {
								font-weight: 600;
							}
						}

						a {
							padding-left: $grid-gutter-width;
						}

						.sub-menu {

							> .menu-item {
								a {
									font-size: 0.8em;
									padding-top: .5em;
									padding-bottom: .5em;
								}

								&:first-child {
									a {
										padding-top: 0;
									}
								}
							}
						}
					}
				}

				// Current menu-ancestor styles
				&.current-menu-ancestor,
				&.current-menu-parent,
				&.current-menu-item {
					> a {
						font-weight: 600;
					}
				}
			}
		}

		.widget {
			padding: $grid-gutter-width/2;
		}
	}
}
