.news-carousel {
    h4 {
        color: $color-primary;
        font-family: 'Oswald';
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
    }
    .uabb-blog-posts-description {
        font-size: 1.6rem;
    }
}
