/****************************************************
*************** General Footer Styles ***************
****************************************************/
#footer {
    // padding: 2rem 0 0 0;
    // background: #505050;

    .uabb-list-icon-text-heading {
        color: $color-primary;
        font-size: 1.6rem;
        font-weight: 400;
    }
    .uabb-list-icon-wrap {

        .uabb-callout-outter {
            margin-right: 5px;
        }
        .uabb-list-icon-text {
            margin-top: 7px;
            font-family: 'Alegreya Sans', sans-serif;
        }
        .uabb-icon i:before {
            font-size: 1.6rem;
        }
    }

    .list-advantages {
        .fl-row-content-wrap {
            padding-top: 1.5em;
        }
    }
}




/****************************************************
******************** Footer Menu ********************
****************************************************/
.footer-menu {
    @extend .hidden-xs;
    @extend .hidden-is;
    padding: 2rem 0 4rem 0;
    background: #505050;

    nav {
        @extend .container;

        #footer-nav {
            align-items: flex-start;

            // Generic #footer-nav list-item styles
            li {
                flex: 0 1 auto;
                text-align: left;
                vertical-align: top;

                &.current-menu-item > a {
                    // font-weight: bold;
                }

                a {
                    color: #fff;
                    padding: 0.5rem 0.5rem 1rem 0.5rem;
                    font-family: 'Oswald';
                    text-transform: uppercase;
                }

                li {

                    a {
                        font-family: 'Source Sans Pro';
                        font-size: 1.4rem;
                        padding: .5rem;
                        text-transform: none;
                    }
                }
            }



            > li {
                // First Level Sub-Menus
                > .sub-menu {
                    .menu-item a {
                        font-size: $font-size-small;
                    }
                }
            }

            ul.sub-menu li {
                border-bottom: 1px solid #737373;
            }

            .start {
                display: none;
            }
        }
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-social-links {
    @extend .container;
    padding: $grid-gutter-width/2 0;
    text-align: center;

    h4 {
        margin: 0 0 $grid-gutter-width/2;
    }

    .social-links-list {
        @extend .nav;
        @extend .nav-center;
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-widgets {
    @extend .container;
}




/****************************************************
******** Copyright Line (Last Line of site) *********
****************************************************/
.copyright {
    padding-bottom: 2.5em;
    background: #fff;

    .cookies-not-accepted & {
        padding-bottom: 5em;

        @media screen and (min-width: $screen-sm-min){
            padding-bottom: 3em
        }
    }
}

.copyright-row {
    @extend .row;
    padding-top: $grid-gutter-width/2;
    font-size: $font-size-small;

    ul li {
        @extend .pipe-before;
        float: left;

        @media screen and (max-width: $screen-is-max){
            float: none;

            &::after,
            &::before {
                display: none;
            }
        }
    }

    // Unternehmensname, Copyright, Adresse etc.
    .copyright-info {
        @extend .nav;
        @include make-sm-column(7);
        padding-bottom: $grid-gutter-width/2;
        text-align: center;

        @media screen and (min-width: $screen-sm-min){
            text-align: left;
        }

        li {
            margin: 0;
            font-size: $font-size-small;
        }
    }

    // Copyright Navigation (Kontakt, Impressum, Datenschutz etc.)
    .copyright-navigation {
        @include make-sm-column(5);
        padding-bottom: $grid-gutter-width/2;

        .menu {
            @include list-unstyled;
            float: right;

            @media screen and (max-width: $screen-is-max){
                float: none;

                li {
                    float: none;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}
