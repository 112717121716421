#header {
    position: fixed;
    z-index: 99;
    width: 100%;
    background-color: rgba(255,255,255,.8);

    padding-bottom: 10px;

    @extend .hidden-xs;
    @extend .hidden-is;

    transition: 200ms ease-in-out all;

    &.affix {
        background: #fff;
        box-shadow: 0 3px 3px rgba(0,0,0,.2);

        .header-content {
            .header-grid {
                padding: 1rem 0;
            }
        }
    }

    // First line of the website. Often contains contact information
    // .header-contact {
    //     font-size: $font-size-small;
    //
    //     .opening-times {
    //         @include make-sm-column(5);
    //     }
    //
    //     .contact-infos {
    //         @include make-sm-column(7);
    //
    //         ul {
    //             @extend .nav;
    //             @extend .nav-right;
    //             line-height: 1;
    //             list-style: none;
    //
    //             li {
    //                 font-size: $font-size-small;
    //
    //                 a,
    //                 i {
    //                     display: inline-block;
    //                     font-size: $font-size-small;
    //                     vertical-align: middle;
    //                     color: $color-primary;
    //
    //                     &::before {
    //                         vertical-align: middle;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    // Wrapper for site logo and header widgets
    .header-content {
        .header-grid {
                transition: 200ms ease-in-out all;
                padding: 3rem 0;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: nowrap;

                >div {
                    width: 33.33333%;
                    // flex-grow: 1;
                }

            .site-logo {

                .logo-link {
                    display: block;
                    height: 100px;
                    background-color: $brand-primary;
                }
            }
            .contact-infos {
                ul {
                    line-height: 1;
                    list-style: none;

                    li {
                        font-size: $font-size-small;
                        margin: 1rem 0 1rem 8rem;
                        white-space: nowrap;

                        a,
                        i {
                            // display: inline-block;
                            font-size: $font-size-small;
                            vertical-align: middle;
                            color: $color-primary;

                            &::before {
                                vertical-align: middle;
                            }
                        }

                        a {
                            color: $color-secondary;
                        }
                    }
                }

            }
            .header-widgets {
                text-align: right;
                padding-right: 10rem;
				
				.widget_icl_lang_sel_widget {
                    // display: none;#
                    float: left;
				}
            }
        }
    }
}
