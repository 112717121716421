#hero {
    // .master-slider-parent, .master-slider, .ms-container, .ms-inner-controls-cont, .ms-slide-container, .ms-slide {
    //   height: 100%;
    // }
    .masterslider-wrap {
        // position: relative;
        .master-slider-parent {
            // position: static!important;
        }
        .master-slider {
            z-index: 10;

            .ms-inner-controls-cont {
                position: static;
            }

            .ms-view,
            .ms-slide {
                // position: static!important;
                @extend .hero-area-content; // @ common/_helper-classes.scss
                // min-height: 300px;
                @media screen and (min-width: $screen-md-min) {
                    max-height: 650px;
                }
            }

            img {
                max-width: none;
            }

            .ms-slide-info {
                position:absolute !important;
                width:100%;
                text-align: center;
                top:0;
                left:50%;
                right:0;
                bottom:0;
                transform:translateX(-50%);

                @media screen and (max-width: $screen-xs-max) {
        			display: none;
        		}

                @media screen and (min-width: $screen-sm-min) {
                    width:95%;
                    max-width:1170px;
                }

                @media screen and (min-width: $screen-md-min) {

                }


                a, button {
                    pointer-events: all !important;
                    position: relative;
                }

                .ms-info {
                    // position:relative;
                    width:100%;
                    // margin-top:3rem;
                    margin-top:0;
                    background: rgba(255, 255, 255, 0.8);
                    // padding:1em;
                    padding:6rem 2rem 2rem;
                    bottom: -4rem;


                    @media screen and (min-width: $screen-sm-min) {
                        max-width:27rem;
                        position:absolute !important;
                        left:6rem;
                        bottom:2rem;
                    }

                    @media screen and (min-width: 880px) {
                        bottom:4rem;
                    }

                    @media screen and (min-width: $screen-lg-min) {
                        bottom:10rem;
                    }

                    .ms-info-price {
                        // font-size: 1.4rem;
                        color: #fff;
                        position: absolute;
                        width: 80px;
                        height: 80px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        top: 0;
                        border-radius: 50%;
                        background-color: $color-tertiary;

                        .price {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 1.4rem;
                        }

                        strong {
                            // font-size: 1.8rem;
                        }
                    }

                    .ms-info-heading {
                        color: $color-primary;
                        text-transform: uppercase;
                        font-family: 'Oswald';
                        font-size: 1.8rem;
                        margin: 0;
                    }

                    .ms-info-subheading {
                        color: $color-primary;
                        text-transform: uppercase;
                        font-family: 'Oswald';
                        font-size: 3rem;
                    }
                    .ms-info-text {
                        margin-bottom: 1.5rem;
                        @media screen and (min-width: $screen-sm-min) {

                        }
                        @media screen and (min-width: $screen-md-min) {
                            margin-bottom: 3rem;
                        }
                        @media screen and (min-width: $screen-lg-min) {

                        }
                    }

                    p {
                        font-size: 1.6rem;
                    }

                    .cta-btn {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -20%);
                    }
                }

                @media screen and (max-width: $screen-xs-max) {
                    // top: auto;
                    bottom: 0 !important;
                    left: auto;
                    transform: none;

                    .ms-info {
                        padding: 0.5em;
                        // max-height: 200px;
                        font-size: 0.75em;
                        // overflow: hidden;
                        margin-top: 0;

                        h2 {
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }

        .ms-bullets {
            z-index: 20;
            width: 50px;

            @media screen and (min-width: $screen-sm-min) {
                bottom: 20px  !important;
            }

            .ms-bullet {
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background: #fff;

                &.ms-bullet-selected {
                    background: $color-primary;
                }
            }
        }
    }
}
