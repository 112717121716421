#desktop-nav {
    @extend .hidden-xs;
    @extend .hidden-is;
    position: relative;
    z-index: 100;
    width: 100%;
    background: #fff;

    nav {
        @extend .container;
    }

    &.affix {
        position: fixed;
        top: 0;
    }

    // Generic Menu item Styles
    li {
        a {
            color: $desktop-nav-link-color;
            padding: .5rem 0;

            @media screen and (min-width: $screen-md-min) {
                padding: 1rem 0;
            }

            &:hover {
                color: $desktop-nav-link-hover-color;
                text-decoration: none;
            }
        }

        // Second Nav Level
        .sub-menu {
            display: none;
            background: $desktop-nav-bg;

            @media screen and (min-width: $screen-sm-min) {
                display: block;
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 10;
                min-width: 100%;
                transition: 0.1s .15s; /* delay of 0.15 seconds on hover off */
            }
        }
    }


    // Menu item description
    .has-description {
        .menu-item-description {
            display: inline-block;
            margin-right: .25em;
            width: 1em;
            vertical-align: middle;
            line-height: 1;

            img {
                display: inline-block;
                width: auto;
                height: auto;
                vertical-align: initial;
            }

            i {
                margin-top: -.265em;
            }
        }
    }
}


// Open First Level Sub-Menus of the last item to the left
#desktop-nav {
    #wp-megamenu-primary_navigation {

        font-family: 'Oswald';
        // text-transform: capitalize;
        >.wpmm-nav-wrap {
            >ul {

                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                white-space: nowrap;

                >li {

                    a {
                        font-size: 1.6rem;
                        color: $color-secondary;
                        border-bottom: 10px solid transparent;
                        margin-bottom: -10px;
                        // text-transform: capitalize;
                        font-weight: normal;
                        text-transform: none;

                        &:hover {
                            color: $color-primary;
                            border-bottom: 10px solid $color-primary;
                        }

                        @media screen and (min-width: $screen-md-min) {

                            font-size: 1.7rem;

                        }
                        @media screen and (min-width: $screen-lg-min) {
                            font-size: 1.8rem;
                            font-weight: normal;
                        }

                    }
                    &.current-menu-item {
                        >a {
                            border-bottom: 10px solid $color-primary;
                            color: $color-primary;
                            color: #000;
                        }
                    }
                }



                li:last-child {
                    .sub-menu {
                        left: auto;
                        right: 0;

                        // Open Second Level Sub-Menus of the last item to the left
                        .sub-menu {
                            right: 100%;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: $screen-sm-min) {

            .start {
                display: none;
            }

            li:hover {
                > .sub-menu {
                    visibility: visible;
                    transition-delay: 0s;
                }
            }

            ul.wp-megamenu-sub-menu > li {
                    padding: 5px;
            }
        }

        @media screen and (min-width: $screen-md-min) {

            .start {
                display: inline-block;
            }
        }

        ul.wp-megamenu-sub-menu {
            background: $color-tertiary;
            margin-bottom: 1rem;

            li {

                a {
                    color: #fff;
                    font-weight: normal;
                    border-bottom: 5px solid transparent;
                    margin-bottom: -5px;
                    &:hover {
                        color: $color-primary !important;
                        border-bottom: 5px solid $color-primary;
                    }
                }
            }
        }
    }
}


// Third Nav Level
#desktop-nav {
    .sub-menu {
        .sub-menu {
            @media screen and (min-width: $screen-sm-min) {
                top: 0;
                left: 100%;
            }
        }
    }
}
