.bottombar {
    display: flex;
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-secondary;
    z-index: 999;
    color: #fff;
    font-family: 'Oswald';
    box-shadow: 0 0 10px rgba(0,0,0,0.75);

    .nav {

        li {
            padding: 14px 0 4px 0;
            font-size: $font-size-large;

            &:last-child {
                border: none;
            }

            i {
                font-size: inherit;
                display: block;
                // color: #fff;
            }

            span {
                font-size: 2rem;
            }

            a {
                color: #fff;
                text-transform: uppercase;

            }
        }
    }
}

.page-wrap {
    @media screen and (max-width: $screen-xs-max) {
        padding-bottom: 2.5em;
    }
}
