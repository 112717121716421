/****************************************************
*************** Scroll To-Top Button ****************
****************************************************/
.to-top {
    position: fixed;
    bottom: 1em;
    right: 1em;
    z-index: 100;
    border: 1px solid #fff;
    padding: .33em .75em;
    background: $color-primary;
    color: #fff;
    opacity: 1;
    mix-blend-mode: luminosity;
    transition: opacity $offcanvas-duration ease-in-out;

    .has-mobile-bottom-bar & {
        @media screen and (max-width: $screen-is-max) {
            bottom: 2.75em;
        }
    }

    .move-left &, .move-right & {
        opacity: 0;
    }
    &:hover{
        background: lighten($color-primary,10%);
        color:#FFF;
    }
}




/****************************************************
********************* CTA Buttons *******************
****************************************************/
%btn {
    display: inline-block;
    padding: 8px 18px 8px 18px;
    border-color: transparent;
    border-radius: 0!important;
    font-family: 'Oswald';
    font-size: 2rem;
    font-weight: 400;
    transition: 250ms all ease-in-out;
    vertical-align: middle;
}

%cta-btn {
    background: $color-tertiary;
    color: #FFF;
    text-transform: uppercase;
    box-shadow: 0 0 6px rgba(0,0,0,.16), 0 0 6px rgba(0,0,0,.23);
}

%cta-btn-hover {
    &:hover,
    &:active,
    &:focus {
        background: darken($color-tertiary,10%) !important;
        color: #FFF !important;
    }
}


// Theme buttons, cf-form buttons, slideshow buttons
a.btn,
a.cta-btn,
a.caldera-forms-modal,
input[type="submit"].btn, {
    @extend %btn;
    @extend %cta-btn;
    @extend %cta-btn-hover;
}

// bb-plugin buttons
.fl-module-content .fl-button-wrap a.fl-button {
    @extend %btn;

    * {
        color: inherit;
    }

    .cta-btn & {
        @extend %cta-btn;
        @extend %cta-btn-hover;
    }
}

// uabb-addon buttons
.fl-module-content .uabb-button-wrap a.uabb-button {
    @extend %btn;

    .cta-btn &{
        @extend %cta-btn;
        @extend %cta-btn-hover;
    }
}

.skd-widget .skd-widget-btn-primary, li.skd-active, li.skd-active:after, .skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-occupancy .skd-room-box-container .skd-room-box .skd-room-nr .skd-circle, .skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-occupancy .skd-editor-mode .skd-room-editor .skd-room-nr .skd-back-to-view, .skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active, .skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:hover:not(.skd-widget-disabled), .skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active::after, .skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:hover:not(.skd-widget-disabled)::after  {
    background: $color-tertiary !important;
    border-color: $color-tertiary !important;
}
.skd-add-room, .skd-plus-sign {
    color: $color-tertiary !important;
}

/****************************************************
******************* Floaty Buttons ******************
****************************************************/

.floaty-wrap {
  display: none;
}

@media screen and (min-width: 845px) and (max-width: 992px), (min-width: 1090px) and (max-width: 1199px), (min-width: 1270px) {

  .floaty-wrap {
      position: fixed;
      left: 0;
      top: 40%;
      z-index: 100;

      .floaty_buttons {
          margin: 0;
          padding: 0;
          list-style: none;

          .menu-item > a{
              margin-bottom: 1rem;
              display: block;
              padding: .5rem .5rem .5rem 2.5rem;
              background: $color-tertiary;
              color: #fff;
              transition: 250ms all ease-in-out;
              transform: translateX(-1.2em);

              @media screen and (max-width: $screen-xs-max) {
                  display: none;
              }
              @media screen and (min-width: $screen-sm-min) {
                  padding: 1rem 1rem 1rem 3rem;
              }
              @media screen and (min-width: $screen-md-min) {
                  padding: 0.8em;
                  padding-left: 2em;
              }


              &:hover {
                  background: $color-tertiary-darken;
                  color: #fff;
                  transform: translateX(0);
                  -ms-transform: translateX(0);
              }
          }
      }
  }
}
